<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'leadStatus'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add Lead Status
            </h5>
            <a
              class="close"
              @click="$store.dispatch('modalClose', 'addLeadPriority')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="group">
                  <label class="group-label">Status Detail</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Title *</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <input
                          type="input"
                          name=""
                          placeholder="Title"
                          id="bank"
                          class="form-control"
                          v-model="formData.title"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Color</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <select
                          name="color"
                          id="color"
                          class="form-control"
                          v-model="formData.color"
                        >
                          <option value="" disabled>Select Color</option>
                          <option  v-for="(color, index) in dataLists.colors" :key="index" :value="color.value">{{color.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 mr-0 pr-0">Status</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <select
                          name="status"
                          id="status"
                          class="form-control"
                          v-model="formData.status"
                        >
                          <option value="1">Active</option>
                          <option value="0">InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
export default {
  data() {
    return {
      formData: {
        title: "",
        percentage: "",
        color: "",
        type: "2",
        status:"1",
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "modalId",
      "dataId",
      "modalState",
      "modalMode",
    ]),
  },
  mounted() {
    //   this.$store.commit('getData','api/items');
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        Services.createLeadPriority(this.formData).then(res=>{
           this.setNotification(res.data.success_message);
           this.$emit('parent-event');
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      } else if (this.modalMode == "edit") {
         Services.updateLeadPriority(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
           this.$emit('parent-event');
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }
    },
    clearData() {
      this.formData.id = "";
      this.formData.title = "";
      this.formData.type = "";
      this.formData.color = "";
      this.formData.percentage = "";
      this.formData.status="1";
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  watch: {
    dataId(value) {
      if (this.modalId == 'leadStatus') {
        this.formData = this.dataLists.property.find(function (data) {
          return data.id == value;
        });
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>